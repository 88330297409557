// @flow

import React from 'react'
import { Row, Col } from 'antd'
import logo from 'images/oq-logo-white.png'
import address from 'images/address.png'
import email from 'images/email.png'
import phone from 'images/phone.png'
import fb from 'images/fb-black.png'
import tt from 'images/tt-black.png'
import li from 'images/li-black.png'
import facebook from 'images/facebook.png'
import twitter from 'images/twitter.png'
import linkedin from 'images/linkedin.png'

import './footer.scss'

function Footer() {
  return (
    <footer className="footer">
      <hr />
      <Row className="footer-wrapper">
        <Col className="copyright" md={8} xs={24}>&#169; 2021 Option Q</Col>
        <Col md={8} xs={0} />
        <Col md={8} xs={24}>
          <Row className="social">
            <a href="https://www.facebook.com/optionq.pt" rel="noopener noreferrer" target="_blank">
              <img src={fb} />
            </a>
            <a className="icon" href="https://twitter.com/option_q" rel="noopener noreferrer" target="_blank">
              <img src={tt} />
            </a>
            <a className="icon" href="https://www.linkedin.com/company/option-q/" rel="noopener noreferrer" target="_blank">
              <img src={li} />
            </a>
          </Row>
        </Col>
      </Row>
    </footer>
  )
}

export default Footer
