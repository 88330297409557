// @flow

import React from 'react'
import { Row, Col, Button } from 'antd'
import address from 'images/address.png'
import email from 'images/email.png'
import phone from 'images/phone.png'

import './contacts.scss'

function ContactsComponent() {
  return (
    <div className="contacts">
      <span className="title">Get in contact</span><br />
      <span className="title">with us</span>
      <address>
        <Row>
          <Col md={{ span: 24 }} xs={{ span: 21, offset: 0 }}>
            <Row className="address-row">
              <Col className="address-icons" md={1} xs={2}>
                <img src={address} />
              </Col>
              <Col className="address-text" span={22}>
                <span className="text-contacts">4000 Porto, Portugal</span>
              </Col>
            </Row>
          </Col>
          <Col md={{ span: 24 }} xs={{ span: 21, offset: 0 }}>
            <Row className="address-row">
              <Col className="address-icons" md={1} xs={2}>
                <img src={email} />
              </Col>
              <Col className="address-text" span={22}>
                <a href="mailto:hello@optionq.pt">
                  <span className="text-contacts">hello@optionq.pt</span>
                </a>
              </Col>
            </Row>
          </Col>
          <Col md={{ span: 24 }} xs={{ span: 21, offset: 0 }}>
            <Row className="address-row">
              <Col className="address-icons" md={1} xs={2}>
                <img src={phone} />
              </Col>
              <Col className="address-text" span={22}>
                <a href="tel:+351 22 111 1148">
                  <span className="text-contacts">+351 22 111 1148</span>
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </address>
      {/*<Button className="contact-button">
        Send an Email
      </Button>*/}
    </div>
  )
}

export default ContactsComponent
