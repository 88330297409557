// @flow

import React from 'react'
import { Row, Col } from 'antd'
import Services from 'components/services'
import VideoBanner from 'components/video-banner'
import op_logo from 'images/op-logo.png'

function Home() {
  return (
    <>
      {/*<section id="home">

      </section>*/}
      <Row className="video-container">
        <Col xs={{ span: 24, offset: 0 }}>
          <VideoBanner
            alt2="Option Q"
            logo={op_logo}
            main="main"
          />
        </Col>
      </Row>
      <Row className="our-services" id="services">
        <Col md={{ span: 24 }} xs={{ span: 24, offset: 0 }}>
          <Services />
        </Col>
      </Row>
    </>
  )
}

export default Home
