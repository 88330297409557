// @flow

import React from 'react'
import { Row, Card } from 'antd'

function ServiceCard({ props }: Object) {
  const { icon, title, content } = props
  return (
    <Card className="services-card">
      <Row className="card-icon-wrapper">
        <div className="card-icon-background">
          <img src={icon} />
        </div>
      </Row>
      <div className="card-title-wrapper">
        <p>{title}</p>
      </div>
    </Card>
  )
}

export default ServiceCard
